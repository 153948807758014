<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            :disabled="disabled"
        >
            {{ $t('storage:send') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['copyToUserStorage']),
        async save() {
            this.disabled = true
            let success = await this.copyToUserStorage()
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {},
    },
}
</script>
