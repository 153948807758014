<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            :disabled="disabled"
        >
            <span v-if="disabled" class="body-2">{{ setProgress }}%</span>
            <span v-else>{{ $t('global:save') }}</span>
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    computed: {
        ...mapGetters(['getProgressStorage']),
        setProgress() {
            return parseInt(
                (this.getProgressStorage.sent /
                    this.getProgressStorage.length) *
                    100
            )
        },
    },

    methods: {
        ...mapActions(['createStorageFile', 'updateStorage']),
        async save() {
            this.disabled = true
            let success = await this.createStorageFile()
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateStorage()
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
