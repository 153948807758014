var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getStorageErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('storage:storageFile')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('storage:storageFile')}})],1)],1),_c('v-row',[(_vm.action === 'create')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"color":"#445D75","counter":"","multiple":"","small-chips":"","placeholder":`${_vm.$t(
                                            'tasks:attachmentsComputer'
                                        )}`,"prepend-icon":"mdi-paperclip","show-size":1000,"error-messages":_vm.getStorageErrors.stepOne
                                                .attachmentsComputer,"accept":"application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*"},scopedSlots:_vm._u([{key:"selection",fn:function({
                                                index,
                                                text,
                                            }){return [(index < 1)?_c('v-chip',{attrs:{"color":"#445D75","dark":"","label":"","small":""}},[_vm._v(_vm._s(text))]):(index === 1)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.getStorageModal .attachmentsComputer .length - 1)+" ")]):_vm._e()]}}],null,false,3532148136),model:{value:(
                                            _vm.getStorageModal.attachmentsComputer
                                        ),callback:function ($$v) {_vm.$set(_vm.getStorageModal, "attachmentsComputer", $$v)},expression:"\n                                            getStorageModal.attachmentsComputer\n                                        "}})],1):_vm._e(),(_vm.action !== 'create')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('global:fileName')},model:{value:(_vm.getStorageModal.name),callback:function ($$v) {_vm.$set(_vm.getStorageModal, "name", $$v)},expression:"getStorageModal.name"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('global:description'),"rows":"1","no-resize":""},model:{value:(
                                            _vm.getStorageModal.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getStorageModal, "description", $$v)},expression:"\n                                            getStorageModal.description\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }