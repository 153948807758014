<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getStorageErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('storage:sendFile') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('storage:sendFile')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            :items="usersList"
                                            multiple
                                            v-model="getStorageModal.users"
                                            :label="`${$t('storage:sendTo')}*`"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getStorageErrors.stepOne.users
                                            "
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    selection.item.lastname
                                                }}</span>
                                                <span
                                                    class="mr-1"
                                                    v-if="
                                                        getStorageModal.users
                                                            .length > 1
                                                    "
                                                    >,</span
                                                >
                                            </template>
                                            <template v-slot:item="item">
                                                <span>{{
                                                    item.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    item.item.lastname
                                                }}</span>
                                            </template></v-select
                                        >
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
        }
    },
    computed: {
        ...mapGetters(['getStorageErrors', 'getStorageModal', 'getUsersList']),

        usersList: {
            get() {
                if (this.getUsersList) return this.getUsersList
                else return []
            },
        },
    },
}
</script>
