<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <RegistryHeader
                    :title="$t('storage:storage')"
                    :profileUrl="'storage'"
                    @clearSearch="clearTable"
                />
                <Table
                    :items="getStorageTable.items"
                    :headers="headers"
                    :length="getStorageTable.pages"
                    :page="getStorageTable.page"
                    :defaultFields="getStorageSearchFields.searchFields"
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                >
                    <Columns
                        @details="editFile"
                        @send="sendtoUser"
                        @open-confirm="openConfirmModal"
                        slot="columns"
                        :items="getStorageTable.items"
                    />
                </Table>
                <!-- ADD AND EDIT ATTACHMENT -->
                <Modal
                    :title="title"
                    :open="open"
                    :width="'600'"
                    :height="'400'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" :action="action" />
                    <Buttons
                        @closeModal="closeModal"
                        slot="buttons"
                        :action="action"
                    />
                </Modal>
                <!-- SEND ATTACHMENT TO USERS -->
                <Modal
                    :title="`${$t('storage:sendFile')}`"
                    :open="openAtt"
                    :width="'600'"
                    :height="'400'"
                    v-on:close="closeAttModal"
                >
                    <AttContent slot="AEContent" />
                    <AttButtons
                        @closeModal="openAtt = false"
                        slot="buttons"
                        :action="action"
                    />
                </Modal>
                <ConfirmModal
                    :openConfirm="openConfirm"
                    @close-confirm="closeConfirmModal"
                >
                    <div slot="main">
                        <ConfirmContent />
                    </div>
                    <div slot="buttons">
                        <div class="d-flex justify-center">
                            <v-btn
                                color="rgb(117, 117, 117)"
                                width="110"
                                height="40"
                                @click="closeConfirmModal()"
                                class="white--text mx-2"
                                >{{ $t('global:cancel') }}</v-btn
                            >
                            <v-btn
                                width="110"
                                height="40"
                                color="primary"
                                class="white--text mx-2"
                                @click="removeAttachment()"
                                >{{ $t('global:remove') }}</v-btn
                            >
                        </div>
                    </div>
                </ConfirmModal>

                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                @click="addFile()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('storage:addFile') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AttContent from './../../../components/Personal/Storage/Modals/SendAttachment/Content'
import AttButtons from './../../../components/Personal/Storage/Modals/SendAttachment/Buttons'

import AEContent from './../../../components/Personal/Storage/Modals/AEModal/Content'
import Buttons from './../../../components/Personal/Storage/Modals/AEModal/Buttons'

import ConfirmContent from './../../../components/Personal/Storage/Modals/ConfirmModal/ConfirmContent'

import Columns from './../../../components/Personal/Storage/Columns'
import store from './../../../store/index'
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['registries', 'global'] },
    components: {
        Buttons,
        AEContent,
        Columns,
        AttContent,
        AttButtons,
        ConfirmContent,
    },

    data() {
        return {
            open: false,
            openAtt: false,
            openConfirm: false,
            action: 'create',
            headers: [
                {
                    text: this.$t('storage:fileName'),
                    value: 'name',
                    width: '15%',
                },
                {
                    text: this.$t('storage:fileDescription'),
                    value: 'description',
                    width: '45%',
                },
                {
                    text: this.$t('storage:transferringPerson'),
                    value: 'transferringPerson',
                    width: '17%',
                },
                {
                    text: this.$t('storage:dateOfTransfer'),
                    value: 'dateOfTransfer',
                    width: '10%',
                },
                {
                    text: this.$t('storage:fileSize'),
                    value: 'size',
                    width: '7%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['getStorageTable', 'getStorageSearchFields']),
        title: {
            get() {
                if (this.action == 'create') {
                    return `${this.$t('storage:addToStorage')}`
                } else if (this.action == 'edit') {
                    return `${this.$t('storage:editToStorage')}`
                }
            },
        },
    },
    methods: {
        ...mapMutations([
            'setStorageTable',
            'setStorageSearch',
            'setModalDetail',
            'setRemoveIdStorage',
            'setStorageFileToShare',
            'clearStorageErrors',
            'clearStorageFields',
            'clearStorageTable',
        ]),
        ...mapActions(['fetchStorage', 'removeStorageItem', 'fetchUsers']),
        async sendtoUser(item) {
            // this.clearSendAttachmentsErrors()
            // this.clearSendAttachmentsModal()
            this.setStorageFileToShare(item)

            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.openAtt = true
        },
        addFile() {
            // this.clearFileErrors()
            // this.clearFileModal()
            this.open = true
            this.action = 'create'
        },
        editFile(item) {
            // this.clearFileErrors()
            this.setModalDetail(item)
            this.open = true
            this.action = 'edit'
        },
        closeAttModal() {
            // this.clearProfileModal()
            this.clearStorageErrors()
            this.clearStorageFields()
            this.openAtt = false
        },
        closeModal() {
            // this.clearProfileModal()
            this.clearStorageErrors()
            this.clearStorageFields()
            this.open = false
        },
        openModal() {
            this.open = true
        },
        openConfirmModal(item) {
            this.setRemoveIdStorage(item._id)
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.clearStorageFields()
            this.openConfirm = false
        },
        async removeAttachment() {
            this.removeStorageItem()
            this.closeConfirmModal()
        },

        setSearchFields(searchFields) {
            this.setStorageSearch(searchFields)
        },
        setSearch(search) {
            this.setStorageTable({ search, page: 1 })
            this.fetchStorage()
        },
        setPage(page) {
            this.setStorageTable({ page })
            this.fetchStorage()
        },
        setSort(sort) {
            this.setStorageTable({ sort })
            this.fetchStorage()
        },
        clearTable() {
            this.clearStorageTable()
            this.fetchStorage()
            this.setStorageTable({})
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/storage/:id')
            store.commit('clearStorageTable')
        store.dispatch('fetchStorage').then(() => next())
    },
}
</script>
