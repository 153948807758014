<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getStorageErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('storage:storageFile') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('storage:storageFile')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" v-if="action === 'create'">
                                        <v-file-input
                                            color="#445D75"
                                            counter
                                            multiple
                                            small-chips
                                            :placeholder="`${$t(
                                                'tasks:attachmentsComputer'
                                            )}`"
                                            prepend-icon="mdi-paperclip"
                                            :show-size="1000"
                                            v-model="
                                                getStorageModal.attachmentsComputer
                                            "
                                            :error-messages="
                                                getStorageErrors.stepOne
                                                    .attachmentsComputer
                                            "
                                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*"
                                        >
                                            <template
                                                v-slot:selection="{
                                                    index,
                                                    text,
                                                }"
                                            >
                                                <v-chip
                                                    v-if="index < 1"
                                                    color="#445D75"
                                                    dark
                                                    label
                                                    small
                                                    >{{ text }}</v-chip
                                                >

                                                <span
                                                    v-else-if="index === 1"
                                                    class="overline grey--text text--darken-3 mx-2"
                                                >
                                                    +{{
                                                        getStorageModal
                                                            .attachmentsComputer
                                                            .length - 1
                                                    }}
                                                </span>
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" v-if="action !== 'create'">
                                        <v-text-field
                                            :label="$t('global:fileName')"
                                            v-model="getStorageModal.name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="$t('global:description')"
                                            rows="1"
                                            no-resize
                                            v-model="
                                                getStorageModal.description
                                            "
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: ['action'],
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
        }
    },

    computed: {
        ...mapGetters(['getStorageErrors', 'getStorageModal']),
    },
}
</script>
