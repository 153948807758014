<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name }}</td>
            <td class="truncate">
                <span>{{ item.description }}</span>
            </td>
            <td class="truncate">
                <span
                    >{{ item.createdBy.name }}
                    {{ item.createdBy.lastname }}</span
                >
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.createdAt).format(`DD-MM-YYYY`)"
                    :secondLine="$moment(item.createdAt).format(`HH:mm`)"
                />
            </td>
            <td class="truncate">
                <span>{{ bytesToMegaBytes(item.size) }}</span>
            </td>
            <td class="d-flex align-center justify-end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="$emit('details', item)"
                            icon
                            small
                            v-on="on"
                        >
                            <v-icon size="20" color="secondary"
                                >mdi-pencil</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>{{ $t('storage:edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            small
                            v-on="on"
                            @click="viewFile(item.fakeName)"
                        >
                            <v-icon size="20" color="grey">mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('storage:show') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            small
                            v-on="on"
                            @click="$emit('send', item)"
                        >
                            <v-icon size="20" color="grey"
                                >mdi-file-send</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>{{ $t('storage:sendToUser') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="$emit('open-confirm', item)" icon small>
                            <v-icon size="20" v-on="on" color="error"
                                >mdi-delete</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>{{ $t('storage:removeFromTheStorage') }}</span>
                </v-tooltip>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapGetters } from 'vuex'
import JWT from 'jsonwebtoken'

export default {
    props: ['items'],
    computed:{
        ...mapGetters(['getProfileDetails'])
    },
    methods: {
        bytesToMegaBytes(a, b = 2) {
            if (0 === a) return '0 Bytes'
            const c = 0 > b ? 0 : b,
                d = Math.floor(Math.log(a) / Math.log(1024))
            return (
                parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
                ' ' +
                ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
            )
        },
        viewFile(file) {
            try {
                let fileLink = document.createElement('a')
                let ext = file.split('.').pop()
                let msExts = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
                const token = JWT.sign(
                    {
                        login: this.getProfileDetails.login,
                        _id: this.getProfileDetails._id,
                        file: file,
                    },
                    process.env.VUE_APP_ATTACHMENT_SECRET,
                    { expiresIn: process.env.VUE_APP_ATTACHMENT_EXPIRED }
                )

                if (msExts.includes(ext)) {
                    fileLink.href = `https://view.officeapps.live.com/op/view.aspx?src=${process.env.VUE_APP_WEBDAVAPI_URL}/${process.env.VUE_APP_WEBDAV_PATH}/storage/${file}?nt=${token}`
               } else {
                    fileLink.href = `${process.env.VUE_APP_WEBDAVAPI_URL}/${process.env.VUE_APP_WEBDAV_PATH}/storage/${file}?nt=${token}`
                }
                fileLink.target = '_blank'
                document.body.appendChild(fileLink)
                fileLink.click()
                document.body.removeChild(fileLink)
            } catch (err) {
                console.error(err)
            }
        },
    },
}
</script>
